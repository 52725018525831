<template>
  <Outer>
    <div class="b-auth">
      <h2 class="b-title _center">
        <template v-if="username">
          {{
            $t('auth---Welcome, {username}!', { username: username })
          }}
        </template>
        <template v-else>Welcome!</template>
      </h2>

      <p class="b-text _note _center">
        {{ $t('auth---Create new password') }}
      </p>

      <div class="b-form _code">
        <div class="b-row">
          <input
            class="b-input"
            :placeholder="$t('auth---Enter password')"
            autofocus
            id="password"
            v-model="password"
            type="password"
            name="password"
          />
        </div>

        <div class="b-row">
          <input
            class="b-input"
            :class="{ _error: password2 && !!errors.password }"
            :placeholder="$t('auth---Repeat password')"
            id="password2"
            v-model="password2"
            type="password"
            name="password2"
          />
          <div
            class="b-error"
            v-if="errors.password"
            v-html="errors.password"
          ></div>
        </div>

        <div class="b-row _space_above">
          <button
            class="b-button _full-width"
            type="button"
            @click="setPassword"
          >
            {{ $t('auth---Save') }}
          </button>
        </div>

        <div v-if="submitError" class="b-row _error">
          <code>{{ submitError }}</code>
        </div>

        <div class="b-row _centered">
          <button class="b-link" @click="changeLocation('auth-log-in' )">
            {{ $t('auth---I remembered my password') }}
          </button>
        </div>
      </div>
    </div>
  </Outer>
</template>
<i18n src="../i18n/translations/reset.json"></i18n>

<script>
import Outer from '../components/Outer.vue';
import {mapActions, mapState} from 'vuex';

export default {
  components: {
    Outer,
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
    code: {
      type: String|Number,
      required: true
    },
  },
  data() {
    return {
      password: null,
      password2: null,
    };
  },
  mounted() {
    document.title = this.$i18n.t('auth---Reset');
  },
  watch: {
    password: function () {
      this.compare();
    },
    password2: function () {
      this.compare();
    },
  },
  computed: {
    ...mapState({
      username: (state) => state.username,
      errors: (state) => state.errors,
      submitError: (state) => state.submitError,
    }),
  },
  methods: {
    ...mapActions(['setError', 'apiPasswordSet', 'setPassword']),
    setPassword() {
      if (
        this.password !== null && this.password2 !== null
        && this.password === this.password2
      ) {
        this.apiPasswordSet({
          userId: this.userId,
          code: this.code,
          password: this.password
        });
      }
    },
    compare() {
      if (this.password !== this.password2) {
        this.setError({
          errorId: 'password',
          message: this.$i18n.t("auth---Password mismatch"),
        });
      } else {
        this.setError({
          errorId: 'password',
          message: null,
        });
      }
    },
    changeLocation(path) {
      this.setError({
        errorId: 'password',
        message: null,
      });
      this.$router.push({ name: path })
    },
  },
};
</script>
